<template>
  <div>
    <h1>redirigiendo.... espere...</h1>

  </div>
</template>

<script>
  export default {
    name: 'certificado',
    mounted(){
      location.replace('https://certificados.ajpyeventos.com');
    },

  }
</script>

<style lang="scss" scoped>

</style>
